import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Layout from 'Components/Layout'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import LoginRegisterForm from 'Components/UI/LoginRegisterForm'
import { Wrapper } from 'Components/MyAccount/SubPage'
import styles from './index.module.scss'

class Login extends Component {
  state = {
    customer: AppStore.getCustomer()
  }
  constructor(props) {
    super(props)
    if (this.state.customer && this.state.customer.id) navigate('/my-account/details/')
  }
  componentDidMount() {
    if (this.state.customer && this.state.customer.id) navigate('/my-account/details/')
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    if (this.state.customer && this.state.customer.id) navigate('/my-account/details/')
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  onLoginCustomer = () => {
    this.setState({ customer: AppStore.getCustomer() })
  }
  render() {
    const toRegister = this.props.pageContext.type === 'register' ? true : false
    return (
      <Layout>
        <Wrapper className={`${styles.parent}`}>
          <section className="u-col-lg-10 u-offset-lg-1">
            <div className='u-height--small' />
            <LoginRegisterForm
              toRegister={toRegister}
              onLoginCustomer={this.onLoginCustomer}
            />
          </section>
          <div className='u-height--only-lg--30' />
        </Wrapper>
      </Layout>
    )
  }
}

export default Login
